import { mapActions } from 'vuex'
import  { myMixin } from '../../../mixins';

export default{
  mixins: [myMixin],
  data: () => ({
        loading: false,
        loadingProgress: false,
        byPercent: false,
        periodicity: 'week',
        shifts: [
          { id: 1, name: 'Manhã', field_hour: 'total_hours_morning', field_class: 'classes_morning'},
          { id: 2, name: 'Tarde', field_hour: 'total_hours_evening', field_class: 'classes_evening' },
          { id: 3, name: 'Noite', field_hour: 'total_hours_night', field_class: 'classes_night' },
        ],
        data: {
          total_ambiences: 0,
          total_days: 5,
          total_hours_morning: 0,
          total_hours_evening: 0,
          total_hours_night: 0,
          classes_morning: 0,
          classes_evening: 0,
          classes_night: 0
        },
        brand_id: null,
        campuses: [],
        ambienceTypes: [],
        workLoadTypes: [],
        min: 0,
        max: 500,
        capacity: [0, 500],
        students: [0, 500],
        colors: ['#66BB6A', '#81D4FA', '#0277BD', '#FFEB3B', '#FF5722'],
        errors: [],
    }),
    
    computed: {
      campus_ids (){
        
        if(this.campuses.length){
          return this.campuses.map(i => i.id).join(',');
        }else{
          return null;
        }
        
      }
    },
    methods: {
      ...mapActions('ambienceOccupation', ['ActionFindAmbienceOccupationIdeal']),
        
      getData(){
      
        this.loading = true;
        this.errors = [];

        const params = {
          campuses:       this.campuses.map(i => i.id),
          ambienceTypes:  this.ambienceTypes.map(i => i.id),
          workLoadTypes:  this.workLoadTypes.map(i => i.id),
          capacity_min:   this.capacity[0],
          capacity_max:   this.capacity[1],
          students_min:   this.students[0],
          students_max:   this.students[1],
        };

        this.ActionFindAmbienceOccupationIdeal(params)
            .then((res) => {
               this.data = res.data;
            }).catch(error => {
              this.errors = error.errors;
            }).finally(() => {
                this.loadingProgress = false;
                this.loading = false;
            })
      },


      getColor(value){

        if(value <= 25){
          return this.colors[0];
        }else if(value <= 50){
          return this.colors[1];
        }else if(value <= 75){
          return this.colors[2];
        }else if(value <= 100){
          return this.colors[3];
        }else{
          return this.colors[4];
        }

      },

      getPercent(shift){
        
        if(this.data){
          
          var percent = (this.getTotalItems(shift) / this.data.total_ambiences) * 100;

          if(percent == Infinity){
            percent = 0;
          }
          
          return percent;

        }else{
          return 100;
        }
      },

      getTotalHous(shift){
        
        if(this.data){
          switch (shift) {
            case 1: return this.data.total_hours_morning;
            case 2: return this.data.total_hours_evening;
            case 3: return this.data.total_hours_night;
            default: return 0;
          }
        }else{
          return 0;
        }

      },

      getTotalClasses(shift){
        
        if(this.data){
          switch (shift) {
            case 1: return this.data.classes_morning;
            case 2: return this.data.classes_evening;
            case 3: return this.data.classes_night;
            default: return 0;
          }
        }else{
          return 0;
        }

      },

      getTotalItems(shift, day){
        
        if(this.data){
          
          var total = 0;

          switch (shift) {
            case 1: total = this.data.classes_morning / this.data.total_days / this.data.total_hours_morning; break;
            case 2: total =  this.data.classes_evening / this.data.total_days / this.data.total_hours_evening; break;
            case 3: total =  this.data.classes_night / this.data.total_days / this.data.total_hours_night; break;
          }

          if(total == Infinity){
            total = 0;
          }

          if(this.periodicity == 'week2'){
            total  = total / 2;
          }

          return total;

        }else{
          return 0;
        }
      }

  },

}
